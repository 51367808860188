import React, { useContext, useState, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { useStaticQuery, graphql, Link } from "gatsby"
import { debounce, get, isEmpty } from 'lodash';
import AuthContext from '../context/AuthProvider';
import CartContext from '../context/CartProvider';
import ContentfulContext from '../context/ContentfulProvider';
// import { geoLocate } from '../helpers/ipstack';
import { getStorage, setStorage, validateEmail, rewriteShippingOptionLabel, dataLayerPush, handleLink } from '../helpers/general';
import { bcApi } from '../helpers/bigcommerce';
import { autoComplete } from '../helpers/here';
import Container from '../components/atoms/Container/Container';
import Button from '../components/atoms/Button/Button';
import Loader from '../components/atoms/Loader/Loader';
import Checkbox from '../components/atoms/Checkbox/Checkbox';
import Radio from '../components/atoms/Radio/Radio';
import Dialog from '../components/atoms/Dialog/Dialog';
import CurrencyFormatter from '../components/atoms/CurrencyFormatter/CurrencyFormatter';
import Layout from '../components/organisms/Layout/Layout';
import Seo from "../components/organisms/Seo/Seo"
import Cart from '../components/organisms/Cart/Cart';
import CartSummary from '../components/molecules/CartSummary/CartSummary';
import ContentfulTranslationText from '../components/atoms/ContentfulTranslationText/ContentfulTranslationText';
// import CartShippingOptions from '../components/molecules/CartShippingOptions/CartShippingOptions'
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs';
// import CartAddOns from '../components/organisms/CartAddOns/CartAddOns';
import * as styles from './confirm-order.module.css';

const ConfirmOrderPage = () => {
    const cartCtx = useContext(CartContext);
    const cartFetched = get(cartCtx, 'state.cartFetched', false);
    const cart = get(cartCtx, 'state.cart', {});
    const checkout = get(cartCtx, 'state.checkout', {});
    const currency = get(cartCtx, 'state.cart.currency.code', 'AUD');
    const numberItems = get(cartCtx, 'state.cart.numberItems');
    const ctxStockAvailability = get(cartCtx, 'state.stockAvailability');
    const ctxSelectedStore = get(cartCtx, 'state.selectedStore');
    const cartShippingMethod = get(cartCtx, 'state.shippingMethod', 'delivery');
    const ctxReloadCheckout = get(cartCtx, 'initCheckout');
    const [fetchingCheckout, setFetchingCheckout] = useState(false);

    const authCtx = useContext(AuthContext);
    const isLoggedIn = get(authCtx, 'state.isLoggedIn', false);
    const accountDetails = get(authCtx, 'state.object', {});
    const refreshUser = authCtx && authCtx.refreshData;

    const contentfulCtx = useContext(ContentfulContext);
    const translations = get(contentfulCtx, 'translationData', {});

    const storeKey = '__jammcd';
    const stepKey = '__jammstep';
    const stateKey = '__jammqs';
    const [init, setInit] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [shippingRequired, setShippingRequired] = useState(true);
    // const [ccEligible, setCcEligible] = useState(true);
    // const ccEligible = true;
    const storedStep = getStorage(stepKey);
    const [cartStep, setCartStep] = useState((storedStep && storedStep !== 'cart') ? false : true);
    const setStep = ((storedStep && storedStep === 'account' && isLoggedIn) ? 'shipping' : storedStep) || 'cart';
    const [currentStep, setCurrentStep] = useState(setStep);
    // const [nextStepValid, setNextStepValid] = useState(true);
    const [allProductsAvailable, setAvailable] = useState(true);
    const [methodBeingSet, settingMethod] = useState(false);

    const [checkoutEmail, setCheckoutEmail] = useState((isLoggedIn && accountDetails.email) || null);
    const [customerMessage, setCustomerMessage] = useState('');
    const [manualShippingAddress, setManualShippingAddress] = useState(true);
    const [shippingAddressResults, setShippingAddressResults] = useState(false); // disabling here API
    const [shippingAddressMessage, setShippingAddressMessage] = useState(false);
    // const [shippingAddressId, setShippingAddressId] = useState(false);
    const [shippingAddress, setShippingAddress] = useState({billingSameAsShipping: true, country_code: ''});
    const [consignment, setConsignment] = useState({});
    const [shippingOptionMessage, setShippingOptionMessage] = useState(get(translations, 'enterShipping', 'Please enter your shipping address to calculate available options'));
    const [shippingOptions, setShippingOptions] = useState([]);
    const [fetchingOptions, setFetchingOptions] = useState(false);
    const [fetchedOptions, setFetchedOptions] = useState(false);
    const [shippingMethod, setShippingMethod] = useState(false);
    const [manualBillingAddress, setManualBillingAddress] = useState(true);
    const [billingAddressResults, setBillingAddressResults] = useState(false);
    const [billingAddressMessage, setBillingAddressMessage] = useState(false);
    const [billingAddress, setBillingAddress] = useState({});
    const [isBillingAddressValid, setBillingAddressValid] = useState(false);
    const [countries, setCountries] = useState([]);
    const [shippingCountryStates, setShippingCountryStates] = useState([]);
    const [billingCountryStates, setBillingCountryStates] = useState([]);
    const [confirmingCheckoutData, setConfirmingCheckoutData] = useState(false);
    const [checkoutFailMessage, setCheckoutFailMessage] = useState([]);
    const [errorBulk, setErrorBulk] = useState([]);
    const [checkoutError, setCheckoutError] = useState(null);
    const [checkShippingAddressLoad, setShippingAddressLoad] = useState(false);

    const stateTranslations = {
        'ACT': 'Australian Capital Territory',
        'NSW': 'New South Wales',
        'NT': 'Northern Territory',
        'QLD': 'Queensland',
        'SA': 'South Australia',
        'TAS': 'Tasmania',
        'VIC': 'Victoria',
        'WA': 'Western Australia'
    };

    const dataQuery = useStaticQuery(graphql`
        query {
            allBigCommerceCountries {
                edges {
                    node {
                        country
                        country_iso2
                        states {
                            resource
                        }
                    }
                }
            }
        }
    `);

    const scrollToSection = (sectionId) => {
        setTimeout(() => {
            const sTop = document.getElementById(sectionId).offsetTop - 112;
            window.scrollTo({
                top: sTop,
                left: 0,
                behavior: 'smooth'
            });
        }, 50);
    }

    const toggleBillingAddressCnC = async () => {
        toggleNextStep('billing');
        try {
            const _newState = await ctxReloadCheckout();
            setShippingAddress(_newState.checkout.consignments[0].shipping_address);
        } catch (_e) {
            console.log(_e);
        }
    };

    const toggleNextStep = (nextStep) => {
        switch (nextStep) {
            case 'cart':
                setCartStep(true);
                setCurrentStep('cart');
                setStorage(stepKey, 'cart', true);
                scrollToSection('section-cart');
                break;

            case 'account':
                setCartStep(false);
                setStorage(stateKey, JSON.stringify({baseAmount: cart.baseAmount, items: cart.numberItems}));
                storeCheckoutData({'cartConfirmed': true});
                let isShippingRequired = shippingRequired;
                if (
                    cart && 
                    'physical_items' in cart.lineItems && 
                    cart.lineItems.physical_items.length === 0 &&
                    'gift_certificates' in cart.lineItems && 
                    cart.lineItems.gift_certificates.length > 0
                ) {
                    // DIGITAL ITEMS IN CART ONLY - No need for shipping
                    isShippingRequired = false;
                    setShippingRequired(isShippingRequired);
                }

                if (isLoggedIn) {
                    // console.log(checkout);
                    if (checkout && checkout.cart && checkout.cart.customer_id === 0) {
                        // console.log("Set customer ID on to Cart");
                        bcApi(`carts/${checkout.id}`, 'PUT', {customer_id: accountDetails.id}).then(async () => {
                            // console.log("Refresh checkout");
                            await ctxReloadCheckout();
                            if (isShippingRequired) {
                                toggleNextStep('shipping');
                            } else {
                                toggleNextStep('billing');
                            }
                        })
                    } else {
                        if (isShippingRequired) {
                            toggleNextStep('shipping');
                        } else {
                            toggleNextStep('billing');
                        }
                    }
                    setCheckoutEmail(accountDetails.email);
                    storeCheckoutData({email: accountDetails.email});
                } else {
                    setCurrentStep('account');
                    setStorage(stepKey, 'account', true);
                    scrollToSection('section-account');
                }
                
                break;

            case 'shipping':
                if (cartShippingMethod === 'collect') {
                    toggleBillingAddressCnC();
                    break;
                }
                if (!shippingRequired) {
                    toggleNextStep('billing');
                    break;
                }
                setCurrentStep('shipping');
                setStorage(stepKey, 'shipping', true);
                scrollToSection('section-shipping');
                if (!shippingAddress.state_or_province && !shippingAddress.country_code) {
                    fetchStates({target: {value: countries[0].node.country_iso2}}, 'shipping');
                } else if (shippingAddress.country_code) {
                    fetchStates({target: {value: shippingAddress.country_code}}, 'shipping');
                }
                checkForShippingMethods();
                break;

            case 'billing':
                setCurrentStep('billing');
                setStorage(stepKey, 'billing', true);
                scrollToSection('section-billing');
                if (!billingAddress.state_or_province && !billingAddress.country_code) {
                    fetchStates({target: {value: countries[0].node.country_iso2}}, 'billing');
                } else if (billingAddress.country_code) {
                    fetchStates({target: {value: billingAddress.country_code}}, 'billing');
                }
                setBillingAddressValid(validateRequiredAddressFields(billingAddress, 'billing'));
                break;

            case 'payment':
                setCurrentStep('payment');
                setStorage(stepKey, 'payment', true);
                scrollToSection('section-payment');
                break;
    
            default:
                break;
        }
    }

    const storeCheckoutData = (obj) => {
        const existingJSON = getStorage(storeKey) || JSON.stringify({});
        const existing = JSON.parse(existingJSON);
        const newSet = JSON.stringify({...existing, ...obj});
        setStorage(storeKey, newSet);
    }

    const removeCheckoutData = (objKey) => {
        const existingJSON = getStorage(storeKey) || JSON.stringify({});
        const existing = JSON.parse(existingJSON);
        delete existing[objKey];
        const newSet = JSON.stringify({...existing});
        setStorage(storeKey, newSet);
    }

    // const setIsValid = (res) => {
    //     setNextStepValid(res);
    // }

    const validateRequiredAddressFields = useCallback((obj, type) => {
        let allValid = true,
            errors = [];

        const requiredAddressFields = [
            'first_name',
            'last_name',
            'phone',
            'address1',
            'city',
            'country_code',
            'postal_code'
        ];

        // console.log(type, obj, shippingCountryStates, billingCountryStates);

        if (
            (type === 'shipping' && !isEmpty(shippingCountryStates)) ||
            (type === 'billing' && !isEmpty(billingCountryStates))
        ) {
            requiredAddressFields.push('state_or_province');
        }
        // console.log(requiredAddressFields);
        requiredAddressFields.map(field => {
            // console.log(field);
            let errorMessage = '',
                thisFail = false;

            if (!(field in obj)) allValid = false;
            else if (obj[field].trim() === '') {
                allValid = false;
                thisFail = true;
                errorMessage = 'This field cannot be empty.';
            } else if (field === 'address1' && (obj[field].toLowerCase().indexOf('po box') > -1 || obj[field].toLowerCase().indexOf('parcel locker') > -1)) {
                allValid = false;
                thisFail = true;
                errorMessage = 'Please enter a valid address.';
            };

            if (thisFail) errors = {...errors, ...{[field] : errorMessage}};

            return true;
        });
        // console.log("Valid Address", allValid);
        setErrorBulk(errors)
        return allValid;
    }, [shippingCountryStates, billingCountryStates]);

    const handleGuestEmail = (email) => {
        // Validate email
        if (!validateEmail(email)) {
            setCheckoutEmail(null);
            removeCheckoutData('email');
            return false;
        }

        setCheckoutEmail(email);
        storeCheckoutData({email})
    }

    const handleCustomerMessage = (message) => {
        const cartId = cart.cartId;

        setCustomerMessage(message);
        bcApi(`checkouts/${cartId}`, 'PUT', {customer_message: message || ''}).then(response => {
            if (response.status === 200) {
                storeCheckoutData({customerMessage: message});
            }
        });
    }

    const assignGuestToOrder = () => {
        // TODO: Handle logged in user
        // TODO: Add guest to order
        dataLayerPush('Checkout as Guest', {name: 'Cart page', content: 'Checkout as Guest'});
        toggleNextStep('shipping');
    }

    const setPreviousAddress = async (type, addressId) => {
        if (accountDetails) {
            const addressDetails = accountDetails.addresses.find(address => address.id === parseInt(addressId));
            const field = {...addressDetails};
            document.getElementById(`${type}_first_name`).value = field.first_name;
            document.getElementById(`${type}_last_name`).value = field.last_name;
            document.getElementById(`${type}_company`).value = field.company;
            document.getElementById(`${type}_phone`).value = field.phone;
            document.getElementById(`${type}_address1`).value = field.address1;
            document.getElementById(`${type}_address2`).value = field.address2;
            document.getElementById(`${type}_city`).value = field.city;
            document.getElementById(`${type}_state`).value = field.state_or_province;
            document.getElementById(`${type}_country_code`).value = field.country_code;
            document.getElementById(`${type}_postal_code`).value = field.postal_code;
            if (type === 'shipping') {
                const newFields = {...shippingAddress, ...field};
                setShippingAddressResults(false);
                storeCheckoutData({shipping: newFields});
                setShippingAddress(newFields);
                // Attempt to get search address
                const suggestions = await autoComplete(`${field.address1}, ${field.city}`);
                let geoAddressId = false;
                if (suggestions && suggestions.type === 'results' && suggestions.results[0]) {
                    geoAddressId = suggestions.results[0].id;
                }
                // setShippingAddressId(geoAddressId);
                setShippingMethod(false);
                removeCheckoutData('shippingMethod');
                checkForShippingMethods(checkoutEmail, newFields, geoAddressId);
            } else if (type === 'billing') {
                const newFields = {...billingAddress, ...field};
                setBillingAddressResults(false);
                setBillingAddress(newFields);
                storeCheckoutData({billing: newFields});
                setBillingAddressValid(validateRequiredAddressFields(newFields, 'billing'));
            }
        }
    }

    const triggerSearch = debounce(async (type, address) => {
        // const suggestions = await predictAddress(address);
        const suggestions = await autoComplete(address);
        // console.log('SUGGESTIONS: ', suggestions);
        if (suggestions) {
            if (suggestions.type === 'results') {
                if (type === 'shipping') {
                    // console.log(suggestions.results);
                    setShippingAddressResults(suggestions.results);
                } else if (type === 'billing') {
                    setBillingAddressResults(suggestions.results);
                }
            } else if (type === 'shipping') {
                setShippingAddressResults(false);
                setShippingAddressMessage(suggestions[suggestions.type]);
            } else if (type === 'billing') {
                setBillingAddressResults(false);
                setBillingAddressMessage(suggestions[suggestions.type]);
            }
        }
    }, 200, {trailing: true});

    const searchAddress = (type, value) => {
        triggerSearch(type, value);
    }

    const selectAddress = async (type, addressId) => {
        // const addressData = await getAddressDetails(addressId);
        const selectedAddress = type === 'shipping' ? shippingAddressResults.filter((address) => {
            return address.locationId === addressId
        }) : billingAddressResults.filter((address) => {
            return address.locationId === addressId
        });

        if (selectedAddress.length > 0) {
            const addressData = selectedAddress[0];
            const field = {};
            const addressProps = addressData.address;
            // console.log(addressProps);
            const addressField = `${(addressProps.unit && `${addressProps.unit}/`) || ''}${`${addressProps.houseNumber} ` || ''}${addressProps.street || ''}`;
            // console.log("Full Address: ", addressField);
            field.address1 = addressField;
            field.city = addressProps.district !== undefined ? addressProps.district : addressProps.city;
            field.state_or_province = addressProps.state !== undefined ? (stateTranslations[addressProps.state] ? stateTranslations[addressProps.state] : addressProps.state) : '';
            field.country_code = addressData.countryCode.substr(0,2);
            field.postal_code = addressProps.postalCode !== undefined ? addressProps.postalCode : '';
            // console.log("Fields:", field);
            document.getElementById(`${type}_address1`).value = field.address1;
            document.getElementById(`${type}_address2`).value = '';
            document.getElementById(`${type}_city`).value = field.city;
            document.getElementById(`${type}_state`).value = field.state_or_province;
            document.getElementById(`${type}_country_code`).value = field.country_code;
            document.getElementById(`${type}_postal_code`).value = field.postal_code;
            if (type === 'shipping') {
                const newFields = {...shippingAddress, ...field};
                setShippingAddressResults(false);
                storeCheckoutData({shipping: newFields});
                setShippingAddress(newFields);
                // setShippingAddressId(addressId);
                setShippingMethod(false);
                removeCheckoutData('shippingMethod');
                checkForShippingMethods(checkoutEmail, newFields, addressId);
            } else if (type === 'billing') {
                const newFields = {...billingAddress, ...field};
                setBillingAddressResults(false);
                setBillingAddress(newFields);
                storeCheckoutData({billing: newFields});
                setBillingAddressValid(validateRequiredAddressFields(newFields, 'billing'));
            }
        } else {
            // TODO: Throw error
        }
    }

    const triggerManualAddress = (type) => {
        if (type === 'shipping') {
            setManualShippingAddress(true);
            // setShippingAddressId(false);
            if (document.getElementById('shipping_state').value === '') {
                document.getElementById('shipping_country_code').value = '';
            }
        } else if (type === 'billing') {
            setManualBillingAddress(true);
            if (document.getElementById('billing_state').value === '') {
                document.getElementById('billing_country_code').value = '';
            }
        }
    }

    const handleShippingAddress = (key, value) => {
        const field = {};
        field[key] = value;
        const newFields = {...shippingAddress, ...field};
        setShippingAddress(newFields);
        // setShippingMethod(false);
        // removeCheckoutData('shippingMethod'); // Theres a reason this was here, but causes issues elsewhere
        storeCheckoutData({shipping: newFields});
    }

    const handleShippingToBilling = (e) => {
        handleShippingAddress('billingSameAsShipping', e.target.checked);
    }

    const handleShippingMethod = useCallback((value, availableShippingOptions = [], currentConsignment = {}) => {
        settingMethod(true);
        const selectedMethod = shippingOptions.length > 0 ? shippingOptions.find(method => method.id === value) : availableShippingOptions.find(method => method.id === value);
        // console.log("Selected method", selectedMethod);
        const cartId = cart.cartId;
        const _consignment = "id" in consignment ? consignment : currentConsignment;
        const requestBody = {
            "shipping_option_id": selectedMethod.id
        };
        bcApi(`checkouts/${cartId}/consignments/${_consignment.id}`, 'PUT', requestBody).then(response => {
            if (response.status === 200 && response.response.data.consignments.length > 0 && 'selected_shipping_option' in response.response.data.consignments[0]) {
                setShippingMethod(selectedMethod);
                storeCheckoutData({shippingMethod: selectedMethod});
                cartCtx.fetchCart().then(() => {
                    settingMethod(false);
                });
            }
        });
    }, [cart, cartCtx, consignment, shippingOptions]);

    const deleteShippingMethod = () => {
        setShippingMethod(false);
        storeCheckoutData({shippingMethod: null});
        cartCtx.removeConsignments(cartCtx.state);
    }

    const checkForShippingMethods = useCallback((newCheckoutEmail, newShippingAddress, newShippingAddressId) => {
        const useCheckoutEmail = newCheckoutEmail || checkoutEmail;
        const useShippingAddress = newShippingAddress || shippingAddress;
        // const useShippingAddressId = newShippingAddressId || shippingAddressId;
        // console.log(useCheckoutEmail, useShippingAddress, cart);

        if (
            useCheckoutEmail &&
            validateRequiredAddressFields(useShippingAddress, 'shipping') && 
            (cart && 'physical_items' in cart.lineItems)
        ) {
            // console.log('cart', cart);
            // console.log('checkout', checkout);
            setFetchingOptions(true);
            setFetchedOptions(false);
            const cartId = cart.cartId;
            const consignment = get(checkout, 'consignments[0]');
            const lineItems = [...cart.lineItems.physical_items, ...cart.lineItems.custom_items];
            let requestBody = [{
                "shipping_address": {
                    "email": useCheckoutEmail,
                    "first_name": useShippingAddress.first_name,
                    "last_name": useShippingAddress.last_name,
                    "company": useShippingAddress.company,
                    "address1": useShippingAddress.address1,
                    "address2": useShippingAddress.address2,
                    "city": useShippingAddress.city,
                    "country_code": useShippingAddress.country_code,
                    "state_or_province": useShippingAddress.state_or_province,
                    "state_or_province_code": useShippingAddress.state_or_province,
                    "postal_code": useShippingAddress.postal_code,
                    "phone": useShippingAddress.phone,
                },
                "line_items": lineItems.map(item => ({'item_id': item.id, 'quantity': item.quantity}))
            }];

            let endpoint = `checkouts/${cartId}/consignments?include=consignments.available_shipping_options`;
            let method = 'POST';
            if (consignment) {
                endpoint = `checkouts/${cartId}/consignments/${consignment.id}?include=consignments.available_shipping_options`;
                method = 'PUT';
                requestBody = requestBody[0];
            }
            // console.log("consignment endpoint", endpoint, method, requestBody);
            bcApi(endpoint, method, requestBody).then(async response => {
                // console.log("consignment response", response);
                if (response.status === 200 && response.response.data.consignments.length > 0 && response.response.data.consignments[0].available_shipping_options.length > 0) {
                    // console.log("Shipping Address ID", useShippingAddressId);

                    // Default to Residential unless we can determine the true nature of the address
                    let addressType = 'Residential';
                    // Address type might not longer used as of now because the shipping options is only for delivery
                    // if (useShippingAddressId) {
                    //     const addressDetail = await fetchAddressType(useShippingAddressId);
                    //     if (addressDetail.response.data && addressDetail.response.data.length > 0) {
                    //         addressType = addressDetail.response.data[0].zonings[0];
                    //         /* Potential options:
                    //             0 Unknown
                    //             1 Commercial/Business
                    //             2 Community Use
                    //             3 Conservation/National Park
                    //             4 Industrial/Utilities
                    //             5 Mixed Use    (This one means it can be both residential and commercial)
                    //             6 Recreational/Open Space
                    //             7 Residential
                    //             8 Rural/Primary Production
                    //             9 Special Use
                    //             10 Transport/Infrastructure
                    //             11 Water

                    //             Videopro requests that all options result to 'Business' except for 5, 7, 8 and 9
                    //         */
                    //         if (
                    //             addressType === 'Mixed Use' ||                  // Option 5
                    //             addressType === 'Residential' ||                // Option 7
                    //             addressType === 'Rural/Primary Production' ||   // Option 8
                    //             addressType === 'Special Use'                   // Option 9
                    //         ) {
                    //             addressType = 'Residential';
                    //         } else addressType = 'Business';
                    //     }
                    // }

                    // console.log("consignment", response.response.data);
                    // console.log('response.response.data.consignments[0]', response.response.data.consignments[0])
                    setConsignment(response.response.data.consignments[0]);
                    const hideAddressType = addressType === 'Business' ? 'Residential' : 'Business';
                    // console.log('Address is', addressType);
                    // console.log('so hide', hideAddressType);
                    // console.log('available options', response.response.data.consignments[0].available_shipping_options);
                    let availableShippingOptions = response.response.data.consignments[0].available_shipping_options.filter(option => option.description.indexOf(`(${hideAddressType}:`) > -1 ? false : true);
                    // Hide Click and Collect option
                    // availableShippingOptions = availableShippingOptions.filter(a => ['click', 'collect', 'pickup'].filter(b => a.description.toLowerCase().includes(b)).length === 0);
                    // Handle free shipping display
                    if (availableShippingOptions.filter(a => a.type === 'freeshipping').length > 0) {
                        availableShippingOptions = availableShippingOptions.filter(a => a.type === 'freeshipping');
                    }
                    setShippingOptions(availableShippingOptions);
                    setFetchedOptions(true);
                    if (availableShippingOptions.length === 1) {
                        // Auto select sole shipping option
                        handleShippingMethod(availableShippingOptions[0].id, availableShippingOptions, response.response.data.consignments[0]);
                    }
                } else if (response.status === 200 && response.response.data.consignments.length > 0 && response.response.data.consignments[0].available_shipping_options.length === 0) {
                //     setConsignment(response.response.data.consignments[0]);
                    // console.log("Status 200, none available");
                    setConsignment({});
                    setShippingOptions([]);
                    setFetchedOptions(true);
                    deleteShippingMethod();
                } else {
                    // console.log("Status error");
                    setConsignment({});
                    setShippingOptions([]);
                    setFetchedOptions(false);
                    deleteShippingMethod();
                }
                setFetchingOptions(false);
            });
        } else {
            setShippingOptionMessage(get(translations, 'moreShipping.title', 'One or more fields are still required before available options can be calculated'));
        }
        // eslint-disable-next-line
    }, [cart, checkout, checkoutEmail, handleShippingMethod, shippingAddress, validateRequiredAddressFields]);

    const assignShippingToOrder = () => {
        // console.log("clicked to move from shipping");
        // Validate we have all required fields
        if (!validateRequiredAddressFields(shippingAddress, 'shipping')) {
            // TODO: return meaningful error to user. This should be a formality here though as this is run as part of the shipping methods check.
            // console.log("Validation Shipping failed");
            return false;
        }

        dataLayerPush('Proceed to Billing', {name: 'Cart page', content: 'Completed shipping step'});
        
        if (shippingAddress.billingSameAsShipping) {
            const newBillingFields = {...shippingAddress};
            delete newBillingFields.comments;
            delete newBillingFields.billingSameAsShipping;
            setBillingCountryStates(shippingCountryStates);
            setBillingAddress(newBillingFields);
            storeCheckoutData({billing: newBillingFields});
            setBillingAddressValid(true);
            // toggleNextStep('payment');
            // console.log("Adding billing to order");
            assignBillingToOrder(newBillingFields);
        } else {
            // console.log("Toggling to billing");
            toggleNextStep('billing');
        }
    }

    const handleBillingAddress = (key, value) => {
        const field = {};
        field[key] = value;
        const newFields = {...billingAddress, ...field};
        setBillingAddress(newFields);
        storeCheckoutData({billing: newFields});
        setBillingAddressValid(validateRequiredAddressFields(newFields, 'billing'));
    }

    const assignBillingToOrder = (newBillingFields) => {
        if (!validateRequiredAddressFields(newBillingFields || billingAddress, 'billing')) {
            // TODO: return meaningful error to user. This should be a formality here though as this is run as part of the shipping methods check.
            // console.log("Validation Billing failed")
            return false;
        }

        if (newBillingFields) {
            // Shipping is bypassing billing and setting as the same
            dataLayerPush('Proceed to Payment', {name: 'Cart page', content: 'Bypassing billing step - same as shipping'});
        } else {
            // Only the button does not pass data to the function
            dataLayerPush('Proceed to Payment', {name: 'Cart page', content: 'Completed billing step'});
        }

        setBillingAddressValid(true);
        const cartId = cart.cartId;
        const billingData = newBillingFields || billingAddress;
        const requestBody = {
            "email": checkoutEmail,
            "first_name": billingData.first_name,
            "last_name": billingData.last_name,
            "company": billingData.company,
            "address1": billingData.address1,
            "address2": billingData.address2,
            "city": billingData.city,
            "country_code": billingData.country_code,
            "state_or_province": billingData.state_or_province,
            "state_or_province_code": billingData.state_or_province,
            "postal_code": billingData.postal_code,
            "phone": billingData.phone
        };

        bcApi(`checkouts/${cartId}/billing-address`, 'POST', requestBody).then(response => {
            if (response.status === 200) {
                toggleNextStep('payment');
            }
        });
    }

    const fetchDefaultStates = (countryData) => {
        const defaultCountry = countryData.length > 1 ? false : countryData[0]; // countryData.find(country => country.node.country_iso2 === 'AU');
        
        if (typeof window !== 'undefined' && defaultCountry) {
            const endpoint = defaultCountry.node.states.resource.substring(1);
            bcApi(`${endpoint}?limit=250`, 'GET', null, 2).then(
                ({ response, status }) => {
                    if (status === 200) {
                        setShippingCountryStates(response);
                        setBillingCountryStates(response);
                    } else {
                        setShippingCountryStates([]);
                        setBillingCountryStates([]);
                    }
                }
            );
        } else {
            setShippingCountryStates([]);
            setBillingCountryStates([]);
        }
    }

    const fetchStates = (evt, type) => {
        const { value: countryIso } = evt.target;
    
        let countryObject = false;
        // console.log(countries, countryIso);
        countries.map(country => {
          if (country.node.country_iso2 === countryIso) {
            countryObject = country;
          }
          return true;
        });
        setIsFetching(true);
    
        if (countryObject) {
            const endpoint = countryObject.node.states.resource.substring(1);
            bcApi(`${endpoint}?limit=250`, 'GET', null, 2).then(
            ({ response, status }) => {
                if (status === 200) {
                    if (type === 'shipping') {
                        setShippingCountryStates(response);
                    } else if (type === 'billing') {
                        setBillingCountryStates(response);
                    }
                } else {
                    if (type === 'shipping') {
                        setShippingCountryStates([]);
                        handleShippingAddress('state_or_province', ''); 
                        checkForShippingMethods();
                    } else if (type === 'billing') {
                        setBillingCountryStates([]);
                        handleBillingAddress('state_or_province', '');
                    }
                }
                checkForShippingMethods();
                setIsFetching(false);
            }
            );
        } else {
            setIsFetching(false);
            if (type === 'shipping') {
                setShippingCountryStates([]);
                handleShippingAddress('state_or_province', ''); 
                checkForShippingMethods();
            } else if (type === 'billing') {
                setBillingCountryStates([]);
                handleBillingAddress('state_or_province', '');
            }
            checkForShippingMethods();
        }
    };

    const validateCheckoutData = (evt) => {
        evt.preventDefault();
        setConfirmingCheckoutData(true);
        setCheckoutFailMessage([]);
        
        bcApi(`checkouts/${checkout.id}`).then(response => {
            // console.log(response);
            if (response.status === 200 && 'data' in response.response) {
                const checkoutData = response.response.data;
                let isCheckoutValid = true;
                const failMessage = [];
                
                // check if logged in user has customer ID set
                if (isLoggedIn && checkoutData.cart.customer_id === 0) {
                    isCheckoutValid = false;
                    console.log("logged in customer not set");
                    failMessage.push('Logged in account is not assigned to the checkout - please refresh.');
                }
                // check for consignment set
                else if ((!checkoutData.consignments || checkoutData.consignments.length === 0) && shippingRequired) {
                    isCheckoutValid = false;
                    console.log("No consignment");
                    failMessage.push('No consignment found for the order. Please revisit step 3 and re-choose a shipping option.');
                }
                // check for selected shipping option
                else if (checkoutData.consignments.length > 0 && !('selected_shipping_option' in checkoutData.consignments[0]) && shippingRequired) {
                    isCheckoutValid = false;
                    console.log("No shipping option");
                    failMessage.push('No shipping option found for the order. Please revisit step 3 and re-choose a shipping option.');
                }
                // check if shipping address is set
                else if (checkoutData.consignments.length > 0 && !('shipping_address' in checkoutData.consignments[0]) && shippingRequired) {
                    isCheckoutValid = false;
                    console.log("no shipping address");
                    failMessage.push('No shipping address found for the order. Please revisit step 3 and re-submit.');
                }
                // check if set shipping address is valid
                else if (checkoutData.consignments.length > 0 && !validateRequiredAddressFields(checkoutData.consignments[0].shipping_address, 'shipping') && shippingRequired) {
                    isCheckoutValid = false;
                    console.log("Shipping address invalid");
                    failMessage.push('The shipping address entered is invalid. Please return to step 3 and ensure all required fields are filled.');
                }
                // check if billing address is set
                else if (!('billing_address' in checkoutData)) {
                    isCheckoutValid = false;
                    console.log("no billing address");
                    failMessage.push('No billing address found for the order. Please revisit step 4 and re-submit.');
                }
                // check if set billing address is valid
                else if (!validateRequiredAddressFields(checkoutData.billing_address, 'billing')) {
                    isCheckoutValid = false;
                    console.log("billing address not valid", checkoutData, checkoutData.billing_address);
                    failMessage.push('The billing address entered is invalid. Please return to step 3 and ensure all required fields are filled.');
                }

                if (!isCheckoutValid) {
                    // TODO: Return meaningful feedback to user on failed check
                    setConfirmingCheckoutData(false);
                    console.log("Checkout invalid returned");
                    setCheckoutFailMessage(failMessage);
                } else {
                    if (typeof window !== 'undefined') {
                        // window.location = '/checkout/';
                        // console.log(checkoutData);
                        const customerRecord = {
                            email: checkoutData.billing_address.email,
                            $first_name: checkoutData.billing_address.first_name,
                            $last_name: checkoutData.billing_address.last_name,
                            $phone_number: checkoutData.billing_address.phone,
                            $city: checkoutData.billing_address.city,
                            $region: checkoutData.billing_address.state_or_province,
                            $country: checkoutData.billing_address.country,
                            $zip: checkoutData.billing_address.postal_code,
                            channel_id: checkoutData.cart.channel_id
                        }
                        dataLayerPush('Checkout', null, checkoutData, handleLink('/checkout/'), false, customerRecord);
                    }
                }
            } else {
                // TODO: Return meaningful feedback to user on failed check
                console.log("Check failed");
                setConfirmingCheckoutData(false);
                setCheckoutFailMessage(["Something unexpected went wrong - please refresh the page."]);
            }
        });

    }

    useMemo(() => {
        // geoLocate().then((userLocation) => {
        //     console.log("UserLocation in cart.js", userLocation);
        //     if (!('region_code' in userLocation.response)) {
        //         userLocation.response.region_code = 'NSW';
        //     }
        //     setCcEligible(['NSW', 'QLD'].indexOf(userLocation.response.region_code) > -1);
        //     setNextStepValid(['NSW', 'QLD'].indexOf(userLocation.response.region_code) === -1);
        // });

        // Force C&C for all users - above code is to set C&C by geography

        /* Handle error codes from BC */
        if (typeof window !== 'undefined' && window.location.search.indexOf('cherr=') > -1 && checkoutError === null) {
            const params = new URLSearchParams(window.location.search);
            const errorCode = params.get('cherr');
            // console.log(errorCode);
            switch (errorCode) {
                case 's0':
                    // Redirect back to BC cart
                    setCheckoutError('An error occurring setting up the payment page for your order. Please try again. If the issue persists, please contact us. (ErrCode: s0)');
                    break;

                case 's1':
                    // Hidden display at step 1 - no customer data
                    setCheckoutError('An error occurring setting up the payment page for your order. Please try again. If the issue persists, please contact us. (ErrCode: s1)');
                    break;

                case 's2':
                    // Hidden display at step 2 - issue with shipping data
                    setCheckoutError('The payment page was not able to setup correctly as it detected there to be an issue with your shipping details. Please review these details and try again. If the issue persists, please contact us. (ErrCode: s2)');
                    break;

                case 's3':
                    // Hidden display at step 3 - issue with billing data
                    setCheckoutError('The payment page was not able to setup correctly as it detected there to be an issue with your billing details. Please review these details and try again. If the issue persists, please contact us. (ErrCode: s3)');
                    break;

                default:
                    break;
            }
        }

        const prevState = getStorage(stateKey);
        let changedState = false;
        if (prevState && cart && cartFetched) {
            const prevStateObj = JSON.parse(prevState);
            // console.log("Cart Fetched", cartFetched);
            // console.log("BaseAmount", prevStateObj.baseAmount, cart.baseAmount);
            // console.log("Items", prevStateObj.items, cart.numberItems);
            if (prevStateObj.baseAmount !== cart.baseAmount || prevStateObj.items !== cart.numberItems) {
                // console.log("Set to change back to cart", prevStateObj.baseAmount !== cart.baseAmount, prevStateObj.items !== cart.numberItems);
                changedState = true;
            }
        }

        if (
            cart && 
            cart.lineItems &&
            'physical_items' in cart.lineItems && 
            cart.lineItems.physical_items.length === 0 &&
            'gift_certificates' in cart.lineItems && 
            cart.lineItems.gift_certificates.length > 0 &&
            shippingRequired === true
        ) {
            // DIGITAL ITEMS IN CART ONLY - No need for shipping
            setShippingRequired(false);

            if (currentStep !== 'cart' && cartShippingMethod === 'collect') {
                // console.log("Digital only - back to cart");
                setCurrentStep('cart');
                setCartStep(true);
                setStorage(stepKey, 'cart', true);
            }
        }

        /* Determine if consignment is set if currentStep is on Payment */
        if (
            (!checkout || 
                (
                    checkout &&
                    (
                        !checkout.consignments ||
                        checkout.consignments.length === 0
                    )
                )
            ) &&
            currentStep === 'payment'
        ) {
            setCurrentStep('shipping');
            setShippingMethod(false);
            removeCheckoutData('shippingMethod');
            setStorage(stepKey, 'shipping', true);
        }

        const storedCheckoutJSON = getStorage(storeKey) || JSON.stringify({});
        const storedCheckout = JSON.parse(storedCheckoutJSON);
        // console.log("Cart:", cart);
        // console.log("Checkout:", checkout);
        // console.log("Stored:", storedCheckout);
        // console.log("Current Step: ", currentStep);
        if (((Object.keys(storedCheckout).length === 0 || changedState) && currentStep !== 'cart') || (currentStep === 'account' && isLoggedIn)) {
            // console.log("reset to cart", currentStep, storedCheckout, changedState);
            setCurrentStep('cart');
            setCartStep(true);
            setStorage(stepKey, 'cart', true);
            // removeCheckoutData('cartConfirmed');
        }
        if (!init) {
            if ('email' in storedCheckout) {
                setCheckoutEmail(storedCheckout.email);
            }
            if ('shipping' in storedCheckout) {
                setShippingAddress(storedCheckout.shipping);
            }
            
            if ('shippingMethod' in storedCheckout) {
                setShippingMethod(storedCheckout.shippingMethod);
            }
            if ('billing' in storedCheckout) {
                setBillingAddress(storedCheckout.billing);
            }
            if ('customerMessage' in storedCheckout) {
                setCustomerMessage(storedCheckout.customerMessage);
            }
            if (countries.length === 0) {
                /* Limit the countries per region */
                const limitCountriesSet = {
                    "/en-au": [
                        'AU',
                        'NZ'
                    ],
                    "/en-eu": [
                        'AT',
                        'BE',
                        'BG',
                        'HR',
                        'CY',
                        'CZ',
                        'DE', // Remove when DE has its own region site
                        'DK',
                        'EE',
                        'FI',
                        'FR',
                        'GR',
                        'HU',
                        'IE',
                        'LV',
                        'LT',
                        'LU',
                        'MT',
                        'NL',
                        'PL',
                        'PT',
                        'RO',
                        'SK',
                        'SI',
                        'ES',
                        'SE'
                    ],
                    "/en-gb": [
                        'GB'
                    ],
                    "/it-it": [
                        'IT'
                    ],
                    "/en-us": [
                        'US'
                    ],
                }
                // console.log(dataQuery.allBigCommerceCountries.edges);
                if (process.env.GATSBY_ENVIRONMENT_PATH) {
                    const limitedCountries = dataQuery.allBigCommerceCountries.edges.filter(country => limitCountriesSet[process.env.GATSBY_ENVIRONMENT_PATH].indexOf(country.node.country_iso2) > -1);
                    // console.log(limitedCountries);
                    if (limitedCountries.length === 1 && shippingAddress.country_code === '') {
                        const tempShippingAddress = shippingAddress;
                        tempShippingAddress.country_code = limitedCountries[0].node.country_iso2;
                        setShippingAddress(tempShippingAddress);
                    }
                    setCountries(limitedCountries);
                    fetchDefaultStates(limitedCountries);
                } else {
                    setCountries(dataQuery.allBigCommerceCountries.edges);
                    fetchDefaultStates(dataQuery.allBigCommerceCountries.edges);
                }
                
            }
            if (currentStep === 'shipping' || currentStep === 'billing') {
                refreshUser();
            } 
            setInit(true);
        }
        // if (
        //     currentStep === 'shipping' && 
        //     storedCheckout.email &&
        //     storedCheckout.shipping.city && 
        //     storedCheckout.shipping.state_or_province &&
        //     storedCheckout.shipping.country_code &&
        //     storedCheckout.shipping.postal_code
        // ) {
        //     console.log("Checking shipping methods");
        //     checkForShippingMethods(storedCheckout.email, storedCheckout.shipping);
        // }
        
        if ((ctxStockAvailability && ctxStockAvailability.length > 0) && ctxSelectedStore && cartShippingMethod === 'collect') {
            const availabilityData = [];
            cart.lineItems.physical_items.map(a => {
                const availableData = ctxStockAvailability.find(s => s.sku === a.sku);
                if (availableData) {
                availabilityData.push(availableData);
                }
                return true;
            });

            const unavailableItems = availabilityData.filter(item => {
                const store = item.locations.filter(a => a.code === ctxSelectedStore.store_id);
                if (store.length === 0 || (parseInt(store[0].qty, 10) === 0 && store[0].vendorOrderETA === null)) {
                    return true;
                }
                return false
            });

            if (unavailableItems.length > 0) {
                setAvailable(false);
            } else if (!allProductsAvailable) {
                setAvailable(true);
            }
        } else if (!allProductsAvailable) {
            setAvailable(true);
        }
    }, [init, cart, checkout, cartFetched, currentStep, dataQuery, countries, refreshUser, isLoggedIn, shippingRequired, ctxStockAvailability, ctxSelectedStore, cartShippingMethod, allProductsAvailable, checkoutError, shippingAddress]);

    useMemo(() => {
        if (currentStep === 'shipping' && 'address1' in shippingAddress && shippingOptions.length === 0 && (cart && 'physical_items' in cart.lineItems) && !checkShippingAddressLoad) {
            // console.log("Check if shipping address is set", shippingAddress, shippingOptions);
            setShippingAddressLoad(true);
            checkForShippingMethods();
        }
    }, [cart, checkForShippingMethods, checkShippingAddressLoad, currentStep, shippingAddress, shippingOptions]);

    useMemo(() => {
        const confirmAccountAssigned = async () => {
            if (!fetchingCheckout && currentStep !== 'cart' && checkout && 'cart' in checkout && checkout.cart.customer_id === 0 && isLoggedIn) {
                setFetchingCheckout(true);
                // console.log("Found logged in, but account not assigned in checkout", checkout);
                // console.log("Attempt to set...", accountDetails);
                // Ensure customer is assigned to the channel of the cart
                const existingChannels = accountDetails.channel_ids;
                const channelId = checkout.cart.channel_id;
                if (Array.isArray(existingChannels)) {
                    // Channels assigned already - check if channel is in list
                    if (existingChannels.indexOf(channelId) === -1) {
                        // Not found - add it
                        existingChannels.push(channelId);
                        await bcApi(`customers`, 'PUT', [{id: accountDetails.id, channel_ids: existingChannels}])
                    }
                } else {
                    // No channels assigned yet. Assign the origin and the channel of the cart
                    const newChannelIds = [accountDetails.origin_channel_id];
                    if (newChannelIds.indexOf(channelId) === -1) {
                        // Not found - add it
                        newChannelIds.push(channelId);
                    }
                    await bcApi(`customers`, 'PUT', [{id: accountDetails.id, channel_ids: newChannelIds}])
                }
                await bcApi(`carts/${checkout.id}`, 'PUT', {customer_id: accountDetails.id})
                // console.log("Refresh checkout state after response", response);
                const latestCheckout = await ctxReloadCheckout();
                // console.log(latestCheckout);
                if (latestCheckout && 'cart' in latestCheckout.checkout && latestCheckout.checkout.cart.customer_id === 0 && isLoggedIn) {
                    // console.log("Resetting back to cart because the cart doesn't have the customer ID assigned");
                    // console.log("Checkout", checkout);
                    setCurrentStep('cart');
                    setCartStep(true);
                    setCheckoutEmail(null);
                    removeCheckoutData('email');
                    setStorage(stepKey, 'cart', true);
                // } else {
                //     console.log("All good - continue");
                }
                setFetchingCheckout(false);
            }
        }
        confirmAccountAssigned();
    },  [checkout, isLoggedIn, ctxReloadCheckout, fetchingCheckout, currentStep, accountDetails]);

    return (
        <Layout>
            <Seo title="Confirm your order" />
            <Container size="large">
                {!cartFetched && !methodBeingSet && (
                    <div className={styles.bcCartContainer}>
                        <div className={styles.bcCartTitle}>
                            <h1 className="h3 text-left">
                                <ContentfulTranslationText keyName="bag">
                                    Bag
                                </ContentfulTranslationText>
                            </h1>
                        </div>
                        <div className={styles.bcCartEmpty}>
                            <div className="text-center">
                                <Loader />
                                <p className="subtitle text-center" style={{paddingTop: "40px"}}>
                                    <ContentfulTranslationText keyName="loadingBag">
                                        Loading bag
                                    </ContentfulTranslationText>...
                                </p>
                            </div>
                        </div>
                    </div>
                )}
                {(cartFetched && !numberItems) && (
                    <div className={styles.bcCartContainer}>
                        <div className={styles.bcCartTitle}>
                            <h1 className="h3 text-left">Cart</h1>
                            <Link to={handleLink("/")} className={styles.continueShopping}>
                                continue shopping
                            </Link>
                        </div>
                        <div className={styles.bcCartEmpty}>
                            <div className="text-center">
                                <p className="subtitle text-center">
                                    <ContentfulTranslationText keyName="emptyBag">
                                        Your bag is empty
                                    </ContentfulTranslationText>.
                                </p>
                                <Link to={handleLink("/")}>
                                    <ContentfulTranslationText keyName="lookAround">
                                        Take a look around
                                    </ContentfulTranslationText>.
                                </Link>
                            </div>
                        </div>
                    </div>
                )}
                {((cartFetched && !!numberItems) || (!!numberItems && methodBeingSet)) && (
                    <div className={styles.cartSection} name="section-cart" id="section-cart">
                        {checkoutError && (
                            <Dialog hideBtnCancel={true} disableBackdropClick={true} disableEscapeKeyDown={true} open={true} size="sm" title="An error occurred" onOk={() => setCheckoutError(false)}>
                                {checkoutError}
                            </Dialog>
                        )}
                        <div className={clsx('grid-gap60 grid-7030', styles.bcCartContainer)}>
                            <div>
                                <div className={styles.breadCrumbsContainer}>
                                    <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {label:(get(translations, 'confirmYourOrder.title', 'Confirm your order'))}]}/>
                                </div>
                                <div className={styles.bcCartTitle}>
                                    {currentStep === 'cart' ? (
                                        <h1 className="h3 text-left">
                                            <ContentfulTranslationText keyName="bag">
                                                Bag
                                            </ContentfulTranslationText>
                                        </h1>
                                    ) : (
                                        <h1 className="h3 text-left">
                                            <ContentfulTranslationText keyName="checkout">
                                                Checkout
                                            </ContentfulTranslationText>
                                        </h1>
                                    )}
                                </div>
                                {currentStep === 'cart' && (
                                    <>
                                        {/* {ccEligible && (
                                            <CartShippingOptions isValid={(res) => setIsValid(res)} setShippingAddress={setShippingAddress} setShippingOptions={setShippingOptions} />
                                        )} */}
                                        <Cart cartType="full" />
                                        {/* <CartAddOns limit={5} columns={5} cart={cart} styles={styles} basicCardView></CartAddOns> */}
                                    </>
                                )}

                                {currentStep !== 'cart' && (
                                    <>
                                        <div className={clsx(styles.step)}>
                                            <div className={styles.stepHeader}>
                                                <span className={clsx(styles.stepNumber, styles.completed)}>1</span>
                                                <span className={styles.stepTitle}>
                                                    <ContentfulTranslationText keyName="bag">
                                                        Bag
                                                    </ContentfulTranslationText>
                                                </span>
                                                <span className={`${styles.stepAction} link fancy lightLink`} role="presentation" onClick={() => toggleNextStep('cart')}>
                                                <ContentfulTranslationText keyName="editBag">
                                                    Edit bag
                                                </ContentfulTranslationText>
                                                </span>
                                            </div>
                                        </div>

                                        <div className={clsx(styles.step)} id="section-account" name="section-account">
                                            <div className={styles.stepHeader}>
                                                <span className={`${styles.stepNumber} ${(currentStep === 'shipping' || currentStep === 'billing' || currentStep === 'payment') ? styles.completed : ''}`}>2</span>
                                                <span className={styles.stepTitle}>
                                                    <ContentfulTranslationText keyName="customer">
                                                        Customer
                                                    </ContentfulTranslationText>
                                                </span>
                                                {(currentStep === 'shipping' || currentStep === 'billing' || currentStep === 'payment') && (
                                                    <>
                                                        <span className={styles.setValue}>{checkoutEmail}</span>
                                                        {!isLoggedIn && (
                                                            <span className={`${styles.stepAction} link fancy lightLink`} role="presentation" onClick={() => toggleNextStep('account')}>
                                                                <ContentfulTranslationText keyName="modify">
                                                                    Modify
                                                                </ContentfulTranslationText>
                                                            </span>
                                                        )}
                                                        {isLoggedIn && (
                                                            <a className={`${styles.stepAction} link fancy lightLink`} href="/logout">
                                                                <ContentfulTranslationText keyName="logout">
                                                                    Logout
                                                                </ContentfulTranslationText>
                                                            </a>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            {currentStep === 'account' && (
                                                <div className={styles.stepBody}>
                                                    {!isLoggedIn && (
                                                        <>
                                                            <ContentfulTranslationText keyName="continueGuestDetail">
                                                                <p>To continue as a Guest, please enter your email address below.</p>
                                                            </ContentfulTranslationText>
                                                            <div className={`flex-between formField ${styles.guestEmailWrapper}`}>
                                                                <input type="email" className={`mr-4 ${styles.checkoutEmail}`} defaultValue={checkoutEmail} onChange={(e) => handleGuestEmail(e.target.value)} />
                                                                <Button level="primary" type="button" disabled={!checkoutEmail} onClick={() => assignGuestToOrder()}>
                                                                    <ContentfulTranslationText keyName="continueGuest">
                                                                        Continue as guest
                                                                    </ContentfulTranslationText>
                                                                </Button>
                                                            </div>
                                                            <div className={`flex-between`}>
                                                                <div>
                                                                    <Checkbox value={true} label={(translations.subscribeNewsletter && translations.subscribeNewsletter.title) || "Subscribe to our Newsletter"} noBg={true} action={() => {}} />
                                                                </div>
                                                                <div>
                                                                    <ContentfulTranslationText keyName="alreadyAccount">
                                                                        Already have an account?
                                                                    </ContentfulTranslationText> <strong><Link to={handleLink("/login/")} className='generic-hover' onClick={(e) => {
                                                                        e.preventDefault();
                                                                        dataLayerPush('Sign in from Checkout', {name: 'Cart page', content: 'Sign in from Checkout'}, null, handleLink('/login/'));
                                                                        return true;
                                                                    }}>
                                                                        <ContentfulTranslationText keyName="signInNow">
                                                                            Sign in now
                                                                        </ContentfulTranslationText>    
                                                                    </Link></strong>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                        {shippingRequired && (
                                            <div className={clsx(styles.step)} id="section-shipping" name="section-shipping">
                                                <div className={styles.stepHeader}>
                                                    <span className={`${styles.stepNumber} ${(currentStep === 'billing' || currentStep === 'payment') ? styles.completed : ''}`}>3</span>
                                                    <span className={styles.stepTitle}>
                                                        {cartShippingMethod === 'collect' ? 'Pickup' : ((translations.shipping && translations.shipping.title) || 'Shipping')}
                                                    </span>

                                                    {cartShippingMethod === 'collect' && (currentStep === 'billing' || currentStep === 'payment') && (
                                                        <>
                                                            <span className={styles.setValue}>
                                                                {shippingAddress && shippingAddress.first_name}<br/>
                                                                {shippingAddress && [
                                                                    shippingAddress.address1,
                                                                    shippingAddress.city,
                                                                    shippingAddress.postal_code,
                                                                    shippingAddress.state_or_province,
                                                                ].join(', ')}
                                                            </span>
                                                        </>
                                                    )}
                                                    {cartShippingMethod !== 'collect' && (currentStep === 'billing' || currentStep === 'payment') && (
                                                        <>
                                                            <span className={styles.setValue}>
                                                                {shippingAddress && shippingAddress.first_name} {shippingAddress && shippingAddress.last_name}<br/>
                                                                {shippingAddress && shippingAddress.address1}
                                                            </span>
                                                        </>
                                                    )}
                                                    {cartShippingMethod !== 'collect' && ['billing', 'payment'].indexOf(currentStep) > -1 && (
                                                        <span className={`${styles.stepAction} link fancy lightLink`} role="presentation" onClick={() => toggleNextStep('shipping')}>
                                                            <ContentfulTranslationText keyName="modify">
                                                                Modify
                                                            </ContentfulTranslationText>
                                                        </span>
                                                    )}
                                                </div>
                                                {currentStep === 'shipping' && (
                                                    <div className={styles.stepBody}>
                                                        {(accountDetails && 'addresses' in accountDetails && accountDetails.addresses.length > 0) && (
                                                            <>
                                                                <div className={`flex-between ${styles.formFields}`}>
                                                                    <div className="formField">
                                                                        <label htmlFor="shipping_preselect_address">
                                                                            <ContentfulTranslationText keyName="selectPrevious">
                                                                                Select previously used address
                                                                            </ContentfulTranslationText>
                                                                        </label>
                                                                        <select id="shipping_preselect_address" onBlur={() => {}} onChange={(e) => setPreviousAddress('shipping', e.target.value)}>
                                                                            <option selected disabled>
                                                                                {(translations.selectPrevious && translations.selectPrevious.title) || "Select an address"}...
                                                                            </option>
                                                                            {accountDetails.addresses.map((address, addressIndex) => (
                                                                                <option key={addressIndex} value={address.id}>
                                                                                    {address.address1}
                                                                                    {address.address2 !== '' ? `, ${address.address2}` : ''}
                                                                                    , {address.city} {address.state_or_province} {address.postal_code} {address.country}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                        <div className={`flex-between ${styles.formFields}`}>
                                                            <div className="formField required">
                                                                <label htmlFor="shipping_first_name">
                                                                    <ContentfulTranslationText keyName="firstName">
                                                                        First name
                                                                    </ContentfulTranslationText>
                                                                </label>
                                                                <input type="text" id="shipping_first_name" defaultValue={shippingAddress.first_name} onChange={(e) => handleShippingAddress('first_name', e.target.value)} onBlur={() => checkForShippingMethods()} />
                                                                {'first_name' in errorBulk && <span className={'genericError floating'}>{errorBulk.first_name}</span>}
                                                            </div>
                                                            <div className="formField required">
                                                                <label htmlFor="shipping_last_name">
                                                                    <ContentfulTranslationText keyName="lastName">
                                                                        Last name
                                                                    </ContentfulTranslationText>
                                                                </label>
                                                                <input type="text" id="shipping_last_name" defaultValue={shippingAddress.last_name} onChange={(e) => handleShippingAddress('last_name', e.target.value)} onBlur={() => checkForShippingMethods()} />
                                                                {'last_name' in errorBulk && <span className={'genericError floating'}>{errorBulk.last_name}</span>}
                                                            </div>
                                                        </div>
                                                        <div className={`flex-between ${styles.formFields}`}>
                                                            <div className="formField">
                                                                <label htmlFor="shipping_company">
                                                                    <ContentfulTranslationText keyName="companyName">
                                                                        Company name
                                                                    </ContentfulTranslationText>
                                                                </label>
                                                                <input type="text" id="shipping_company" defaultValue={shippingAddress.company} onChange={(e) => handleShippingAddress('company', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className={`flex-between ${styles.formFields}`}>
                                                            <div className="formField required">
                                                                <label htmlFor="shipping_phone">
                                                                    <ContentfulTranslationText keyName="phoneNumber">
                                                                        Phone number
                                                                    </ContentfulTranslationText>
                                                                </label>
                                                                <input type="text" id="shipping_phone" defaultValue={shippingAddress.phone} onChange={(e) => handleShippingAddress('phone', e.target.value)} onBlur={() => checkForShippingMethods()} />
                                                                {'phone' in errorBulk && <span className={'genericError floating'}>{errorBulk.phone}</span>}
                                                            </div>
                                                        </div>
                                                        {!manualShippingAddress && (
                                                            <div className={`flex-between ${styles.formFields}`}>
                                                                <div className="formField required">
                                                                    <label htmlFor="shipping_address_search">
                                                                        <ContentfulTranslationText keyName="addressSearch">
                                                                            Address search
                                                                        </ContentfulTranslationText>
                                                                    </label>
                                                                    <span role="presentation" className={`${styles.manualAddressTrigger} link fancy lightLink`} onClick={() => triggerManualAddress('shipping')}>
                                                                        <ContentfulTranslationText keyName="addressNotFound">
                                                                            Can't find your address or live outside Australia? Manually enter your address.
                                                                        </ContentfulTranslationText>
                                                                    </span>
                                                                    <input type="text" autoComplete="new-password" id="shipping_address_search" onChange={(e) => searchAddress('shipping', e.target.value)} />
                                                                    {shippingAddressResults && (
                                                                        <div className={styles.addressSearchResults}>
                                                                            {shippingAddressResults.map((address, addressIndex) => {
                                                                                return (
                                                                                    <div key={addressIndex} role="presentation" onClick={() => selectAddress('shipping', address.locationId)}>
                                                                                        {/* {address.address} */}
                                                                                        {address.address ? (
                                                                                            address.address.label || (
                                                                                                `${address.address.unit ? `${address.address.unit}/` : ''}${address.address.houseNumber ? `${address.address.houseNumber} ` : ''}
                                                                                                ${address.address.street ? `${address.address.street}, ` : ''}
                                                                                                ${address.address.district ? `${address.address.district}, ` : (address.address.city ? `${address.address.city}, ` : '')}
                                                                                                ${address.address.state ? `${address.address.state}, ` : ''}
                                                                                                ${address.address.country ? `${address.address.country} ` : ''}`
                                                                                            )
                                                                                         ) : (address.title || address.label)}
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                    {shippingAddressMessage && (
                                                                        <div className={styles.addressMessage}></div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className={`flex-between ${styles.formFields}`}>
                                                            <div className="formField required">
                                                                <label htmlFor="shipping_address1">
                                                                    <ContentfulTranslationText keyName="address">
                                                                        Address
                                                                    </ContentfulTranslationText>
                                                                </label>
                                                                <input type="text" id="shipping_address1" disabled={!manualShippingAddress} defaultValue={shippingAddress.address1} onChange={(e) => handleShippingAddress('address1', e.target.value)} onBlur={() => checkForShippingMethods()} />
                                                                {'address1' in errorBulk && <span className={'genericError floating'}>{errorBulk.address1}</span>}
                                                                <span className={styles.fieldNote}>
                                                                    <ContentfulTranslationText keyName="addressNote">
                                                                        Please Note - We cannot ship to P.O Boxes or Parcel Lockers, please use a different address.
                                                                    </ContentfulTranslationText>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className={`flex-between ${styles.formFields}`}>
                                                            <div className="formField">
                                                                <label htmlFor="shipping_address2">
                                                                    <ContentfulTranslationText keyName="address2">
                                                                        Address line 2
                                                                    </ContentfulTranslationText>
                                                                </label>
                                                                <input type="text" id="shipping_address2" disabled={!manualShippingAddress}  defaultValue={shippingAddress.address2} onChange={(e) => handleShippingAddress('address2', e.target.value)} onBlur={() => checkForShippingMethods()} />
                                                            </div>
                                                        </div>
                                                        <div className={`flex-between ${styles.formFields}`}>
                                                            <div className="formField required">
                                                                <label htmlFor="shipping_city">
                                                                    <ContentfulTranslationText keyName="suburb">
                                                                        Suburb
                                                                    </ContentfulTranslationText>
                                                                </label>
                                                                <input type="text" id="shipping_city" disabled={!manualShippingAddress}  defaultValue={shippingAddress.city} onChange={(e) => handleShippingAddress('city', e.target.value)} onBlur={() => checkForShippingMethods()} />
                                                                {'city' in errorBulk && <span className={'genericError floating'}>{errorBulk.city}</span>}
                                                            </div>
                                                            <div className="formField required">
                                                                <label htmlFor="shipping_country_code">
                                                                    <ContentfulTranslationText keyName="country">
                                                                        Country
                                                                    </ContentfulTranslationText>
                                                                </label>
                                                                <select id="shipping_country_code" disabled={!manualShippingAddress}  defaultValue={shippingAddress.country_code || (countries.length > 1 ? '' : countries[0]?.node.country_iso2)} onBlur={(e) => {handleShippingAddress('country_code', e.target.value); checkForShippingMethods()}} onChange={(e) => {fetchStates(e, 'shipping');}}>
                                                                    {countries.length > 1 ? (
                                                                        <option value=''>
                                                                            {(translations.selectCountry && translations.selectCountry.title) || "Select a country"}...
                                                                        </option>
                                                                    ) : (
                                                                        <option disabled>
                                                                            {(translations.selectCountry && translations.selectCountry.title) || "Select a country"}...
                                                                        </option>
                                                                    )}
                                                                    {countries.map((country, countryIndex) => (
                                                                        <option key={countryIndex} value={country.node.country_iso2}>
                                                                        {country.node.country}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                                {'country_code' in errorBulk && <span className={'genericError floating'}>{errorBulk.country_code}</span>}
                                                            </div>
                                                        </div>
                                                        <div className={`flex-between ${styles.formFields}`}>
                                                            <div className={`formField${!isEmpty(shippingCountryStates) ? ' required' : ''}`}>
                                                                {!isEmpty(shippingCountryStates) && (
                                                                    <>
                                                                        <label htmlFor="shipping_state">
                                                                            <ContentfulTranslationText keyName="state">
                                                                                State
                                                                            </ContentfulTranslationText>
                                                                        </label>
                                                                        <select
                                                                            id="shipping_state" 
                                                                            disabled={!manualShippingAddress} 
                                                                            defaultValue={shippingAddress.state_or_province}
                                                                            onBlur={(e) => {handleShippingAddress('state_or_province', e.target.value); checkForShippingMethods();}}
                                                                        >
                                                                        <option>
                                                                            {(translations.selectState && translations.selectState.title) || "Select a state"}...
                                                                        </option>
                                                                        {shippingCountryStates.map((state, stateIndex) => (
                                                                            <option key={stateIndex} value={state.state}>
                                                                            {state.state}
                                                                            </option>
                                                                        ))}
                                                                        </select>
                                                                    </>
                                                                )}
                                                                {/* {isEmpty(shippingCountryStates) && (
                                                                    <input
                                                                        type="text"
                                                                        id="shipping_state" 
                                                                        disabled={!manualShippingAddress} 
                                                                        defaultValue={shippingAddress.state_or_province}
                                                                        onChange={(e) => handleShippingAddress('state_or_province', e.target.value)}
                                                                        onBlur={() => checkForShippingMethods()}
                                                                    />
                                                                )} */}
                                                                {isFetching && (
                                                                    <div className={styles.spinnerWrapper}>
                                                                        <Loader />
                                                                    </div>
                                                                )}
                                                                {'state_or_province' in errorBulk && <span className={'genericError floating'}>{errorBulk.state_or_province}</span>}
                                                            </div>
                                                            <div className="formField required">
                                                                <label htmlFor="shipping_postal_code">
                                                                    <ContentfulTranslationText keyName="postcode">
                                                                        Postcode
                                                                    </ContentfulTranslationText>
                                                                </label>
                                                                <input type="text" id="shipping_postal_code" disabled={!manualShippingAddress}  defaultValue={shippingAddress.postal_code} onChange={(e) => handleShippingAddress('postal_code', e.target.value)} onBlur={() => checkForShippingMethods()} />
                                                                {'postal_code' in errorBulk && <span className={'genericError floating'}>{errorBulk.postal_code}</span>}
                                                            </div>
                                                        </div>
                                                        <div className={`flex-between ${styles.formFields}`} style={{"display": "none"}}>
                                                            <div className="formField">
                                                                <label htmlFor="shipping_comments">
                                                                    <ContentfulTranslationText keyName="comments">
                                                                        Comments
                                                                    </ContentfulTranslationText>
                                                                </label>
                                                                <textarea id="shipping_comments" defaultValue={shippingAddress.comments} onChange={(e) => handleShippingAddress('comments', e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className={`flex-between ${styles.formFields}`}>
                                                            <div className="formField required">
                                                                <label htmlFor="shipping_methods">
                                                                    <ContentfulTranslationText keyName="shippingMethods">
                                                                        Shipping methods
                                                                    </ContentfulTranslationText>
                                                                </label>
                                                                <span role="presentation" className={`${styles.supportFunction} link fancy lightLink`} onClick={() => checkForShippingMethods()}>
                                                                    <ContentfulTranslationText keyName="calculateOptions">
                                                                        Calculate shipping options
                                                                    </ContentfulTranslationText>
                                                                </span>
                                                                <div id="shipping_methods" className={`${styles.shippingMethods} ${shippingOptions.length > 0 ? styles.displayOptions : ''}`}>
                                                                    {(shippingOptions.length === 0 && !fetchingOptions && !fetchedOptions) && (
                                                                        <span>{shippingOptionMessage}</span>
                                                                    )}
                                                                    {fetchingOptions && (
                                                                        <span>
                                                                            <ContentfulTranslationText keyName="calculatingOptions">
                                                                                Calculating options - Please wait
                                                                            </ContentfulTranslationText>...
                                                                        </span>
                                                                    )}
                                                                    {(shippingOptions.length === 0 && !fetchingOptions && fetchedOptions) && (
                                                                        // <span>Unfortunately one or more items in your cart can't be shipped to your location. Please choose a different delivery address.</span>
                                                                        <span>
                                                                            <ContentfulTranslationText keyName="shippingUnavailable">
                                                                                No available shipping option was found for your location. Subscribe to our newsletter to stay tuned for when we’ll open up shipping to your location.
                                                                            </ContentfulTranslationText>
                                                                        </span>
                                                                    )}
                                                                    {(shippingOptions.length > 0 && !fetchingOptions && fetchedOptions) && (
                                                                        <>
                                                                            {shippingOptions.map((option, optionIndex) => (
                                                                                <div key={optionIndex}>
                                                                                    <Radio value={option.id} label={<>{rewriteShippingOptionLabel((translations[option.description] ? translations[option.description].title : option.description), true)} - <CurrencyFormatter amount={option.cost} currency={currency} taxCalc={false} /></>} name="shipping_method" action={handleShippingMethod} isChecked={shippingMethod ? shippingMethod.id === option.id : false} />
                                                                                </div>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                    {methodBeingSet && (
                                                                        <div className={styles.loadingShipping}>
                                                                            <Loader />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`flex-between ${styles.formFields}`}>
                                                            <div className="formField">
                                                                <Checkbox value={true} isChecked={shippingAddress.billingSameAsShipping} action={handleShippingToBilling} label={(translations.billingIsShipping && translations.billingIsShipping.title) || "My billing address is the same as my shipping address"} noBg={true} />
                                                            </div>
                                                        </div>
                                                        <Button level="primary" type="button" disabled={!shippingMethod || methodBeingSet} onClick={() => assignShippingToOrder()} className={styles.full}>
                                                            <ContentfulTranslationText keyName="continue">
                                                                Continue
                                                            </ContentfulTranslationText>
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        <div className={clsx(styles.step)} id="section-billing" name="section-billing">
                                            <div className={styles.stepHeader}>
                                            <span className={`${styles.stepNumber} ${currentStep === 'payment' ? styles.completed : ''}`}>{shippingRequired ? '4' : '3'}</span>
                                                <span className={styles.stepTitle}>
                                                    <ContentfulTranslationText keyName="billing">
                                                        Billing
                                                    </ContentfulTranslationText>
                                                </span>
                                                {currentStep === 'payment' && (
                                                    <>
                                                        <span className={styles.setValue}>
                                                            {billingAddress && billingAddress.first_name} {billingAddress && billingAddress.last_name}<br/>
                                                            {billingAddress && billingAddress.address1}
                                                        </span>
                                                        <span className={`${styles.stepAction} link fancy lightLink`} role="presentation" onClick={() => toggleNextStep('billing')}>
                                                            <ContentfulTranslationText keyName="modify">
                                                                Modify
                                                            </ContentfulTranslationText>
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                            {currentStep === 'billing' && (
                                                <div className={styles.stepBody}>
                                                    {(accountDetails && 'addresses' in accountDetails && accountDetails.addresses.length > 0) && (
                                                        <>
                                                            <div className={`flex-between ${styles.formFields}`}>
                                                                <div className="formField">
                                                                    <label htmlFor="billing_preselect_address">
                                                                        <ContentfulTranslationText keyName="selectPrevious">
                                                                            Select previously used address
                                                                        </ContentfulTranslationText>
                                                                    </label>
                                                                    <select id="billing_preselect_address" onBlur={() => {}} onChange={(e) => setPreviousAddress('billing', e.target.value)}>
                                                                        <option selected disabled>
                                                                            {(translations.selectAddress && translations.selectAddress.title) || "Select an address"}...
                                                                        </option>
                                                                        {accountDetails.addresses.map((address, addressIndex) => (
                                                                            <option key={addressIndex} value={address.id}>
                                                                                {address.address1}
                                                                                {address.address2 !== '' ? `, ${address.address2}` : ''}
                                                                                , {address.city} {address.state_or_province} {address.postal_code} {address.country}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    <div className={`flex-between ${styles.formFields}`}>
                                                        <div className="formField required">
                                                            <label htmlFor="billing_first_name">
                                                                <ContentfulTranslationText keyName="firstName">
                                                                    First name
                                                                </ContentfulTranslationText>
                                                            </label>
                                                            <input type="text" id="billing_first_name" defaultValue={billingAddress.first_name} onChange={(e) => handleBillingAddress('first_name', e.target.value)} />
                                                            {'first_name' in errorBulk && <span className={'genericError floating'}>{errorBulk.first_name}</span>}
                                                        </div>
                                                        <div className="formField required">
                                                            <label htmlFor="billing_last_name">
                                                                <ContentfulTranslationText keyName="lastName">
                                                                    Last name
                                                                </ContentfulTranslationText>
                                                            </label>
                                                            <input type="text" id="billing_last_name" defaultValue={billingAddress.last_name} onChange={(e) => handleBillingAddress('last_name', e.target.value)} />
                                                            {'last_name' in errorBulk && <span className={'genericError floating'}>{errorBulk.last_name}</span>}
                                                        </div>
                                                    </div>
                                                    <div className={`flex-between ${styles.formFields}`}>
                                                        <div className="formField">
                                                            <label htmlFor="billing_company">
                                                                <ContentfulTranslationText keyName="companyName">
                                                                    Company name
                                                                </ContentfulTranslationText>
                                                            </label>
                                                            <input type="text" id="billing_company" defaultValue={billingAddress.company} onChange={(e) => handleBillingAddress('company', e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className={`flex-between ${styles.formFields}`}>
                                                        <div className="formField required">
                                                            <label htmlFor="billing_phone">
                                                                <ContentfulTranslationText keyName="phoneNumber">
                                                                    Phone number
                                                                </ContentfulTranslationText>
                                                            </label>
                                                            <input type="text" id="billing_phone" defaultValue={billingAddress.phone} onChange={(e) => handleBillingAddress('phone', e.target.value)} />
                                                            {'phone' in errorBulk && <span className={'genericError floating'}>{errorBulk.phone}</span>}
                                                        </div>
                                                    </div>
                                                    {!manualBillingAddress && (
                                                        <div className={`flex-between ${styles.formFields}`}>
                                                            <div className="formField required">
                                                                <label htmlFor="billing_address_search">
                                                                    <ContentfulTranslationText keyName="addressSearch">
                                                                        Address search
                                                                    </ContentfulTranslationText>
                                                                </label>
                                                                <span role="presentation" className={`${styles.manualAddressTrigger} link fancy lightLink`} onClick={() => triggerManualAddress('billing')}>
                                                                    <ContentfulTranslationText keyName="addressNotFound">
                                                                        Can't find your address? Manually enter your address.
                                                                    </ContentfulTranslationText>
                                                                </span>
                                                                <input type="text" autoComplete="new-password" id="billing_address_search" onChange={(e) => searchAddress('billing', e.target.value)} />
                                                                {billingAddressResults && (
                                                                    <div className={styles.addressSearchResults}>
                                                                        {billingAddressResults.map((address, addressIndex) => {
                                                                            return (
                                                                                <div key={addressIndex} role="presentation" onClick={() => selectAddress('billing', address.locationId)}>
                                                                                    {address.address ? (
                                                                                        address.address.label || (
                                                                                            `${address.address.unit ? `${address.address.unit}/` : ''}${address.address.houseNumber ? `${address.address.houseNumber} ` : ''}
                                                                                            ${address.address.street ? `${address.address.street}, ` : ''}
                                                                                            ${address.address.district ? `${address.address.district}, ` : (address.address.city ? `${address.address.city}, ` : '')}
                                                                                            ${address.address.state ? `${address.address.state}, ` : ''}
                                                                                            ${address.address.country ? `${address.address.country} ` : ''}`
                                                                                        )
                                                                                    ) : (address.title || address.label)}
                                                                                </div>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                )}
                                                                {billingAddressMessage && (
                                                                    <div className={styles.addressMessage}></div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className={`flex-between ${styles.formFields}`}>
                                                        <div className="formField required">
                                                            <label htmlFor="billing_address1">
                                                                <ContentfulTranslationText keyName="address">
                                                                    Address
                                                                </ContentfulTranslationText>
                                                            </label>
                                                            <input type="text" id="billing_address1" disabled={!manualBillingAddress} defaultValue={billingAddress.address1} onChange={(e) => handleBillingAddress('address1', e.target.value)} />
                                                            {'address1' in errorBulk && <span className={'genericError floating'}>{errorBulk.address1}</span>}
                                                        </div>
                                                    </div>
                                                    <div className={`flex-between ${styles.formFields}`}>
                                                        <div className="formField">
                                                            <label htmlFor="billing_address2">
                                                                <ContentfulTranslationText keyName="address2">
                                                                    Address line 2
                                                                </ContentfulTranslationText>
                                                            </label>
                                                            <input type="text" id="billing_address2" disabled={!manualBillingAddress} defaultValue={billingAddress.address2} onChange={(e) => handleBillingAddress('address2', e.target.value)} />
                                                        </div>
                                                    </div>
                                                    <div className={`flex-between ${styles.formFields}`}>
                                                        <div className="formField required">
                                                            <label htmlFor="billing_city">
                                                                <ContentfulTranslationText keyName="suburb">
                                                                    Suburb
                                                                </ContentfulTranslationText>
                                                            </label>
                                                            <input type="text" id="billing_city" disabled={!manualBillingAddress} defaultValue={billingAddress.city} onChange={(e) => handleBillingAddress('city', e.target.value)} />
                                                            {'city' in errorBulk && <span className={'genericError floating'}>{errorBulk.city}</span>}
                                                        </div>
                                                        <div className="formField required">
                                                            <label htmlFor="billing_country_code">
                                                                <ContentfulTranslationText keyName="country">
                                                                    Country
                                                                </ContentfulTranslationText>
                                                            </label>
                                                            <select id="billing_country_code" disabled={!manualBillingAddress} defaultValue={billingAddress.country_code || (countries.length > 1 ? '' : countries[0]?.node.country_iso2)} onBlur={(e) => handleBillingAddress('country_code', e.target.value)} onChange={(e) => fetchStates(e, 'billing')}>
                                                                {countries.length > 1 ? (
                                                                    <option value=''>
                                                                        {(translations.selectCountry && translations.selectCountry.title) || "Select a country"}...
                                                                    </option>
                                                                ) : (
                                                                    <option disabled>
                                                                        {(translations.selectCountry && translations.selectCountry.title) || "Select a country"}...
                                                                    </option>
                                                                )}
                                                                {countries.map((country, countryIndex) => (
                                                                    <option key={countryIndex} value={country.node.country_iso2}>
                                                                    {country.node.country}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {'country_code' in errorBulk && <span className={'genericError floating'}>{errorBulk.country_code}</span>}
                                                        </div>
                                                    </div>
                                                    <div className={`flex-between ${styles.formFields}`}>
                                                        <div className={`formField${!isEmpty(billingCountryStates) ? ' required' : ''}`}>
                                                            {!isEmpty(billingCountryStates) && (
                                                                <>
                                                                    <label htmlFor="billing_state">
                                                                        <ContentfulTranslationText keyName="state">
                                                                            State
                                                                        </ContentfulTranslationText>
                                                                    </label>
                                                                    <select
                                                                        id="billing_state" 
                                                                        disabled={!manualBillingAddress}
                                                                        defaultValue={billingAddress.state_or_province}
                                                                        onBlur={(e) => handleBillingAddress('state_or_province', e.target.value)}
                                                                    >
                                                                    <option>
                                                                        {(translations.selectState && translations.selectState.title) || "Select a state"}...
                                                                    </option>
                                                                    {billingCountryStates.map((state, stateIndex) => (
                                                                        <option key={stateIndex} value={state.state}>
                                                                        {state.state}
                                                                        </option>
                                                                    ))}
                                                                    </select>
                                                                </>
                                                            )}
                                                            {/* {isEmpty(billingCountryStates) && (
                                                                <input
                                                                    type="text"
                                                                    id="billing_state" 
                                                                    disabled={!manualBillingAddress}
                                                                    defaultValue={billingAddress.state_or_province}
                                                                    onChange={(e) => handleBillingAddress('state_or_province', e.target.value)}
                                                                />
                                                            )} */}
                                                            {isFetching && (
                                                                <div className={styles.spinnerWrapper}>
                                                                    <Loader />
                                                                </div>
                                                            )}
                                                            {'state_or_province' in errorBulk && <span className={'genericError floating'}>{errorBulk.state_or_province}</span>}
                                                        </div>
                                                        <div className="formField required">
                                                            <label htmlFor="billing_postal_code">
                                                                <ContentfulTranslationText keyName="postcode">
                                                                    Postcode
                                                                </ContentfulTranslationText>
                                                            </label>
                                                            <input type="text" id="billing_postal_code" disabled={!manualBillingAddress} defaultValue={billingAddress.postal_code} onChange={(e) => handleBillingAddress('postal_code', e.target.value)} />
                                                            {'postal_code' in errorBulk && <span className={'genericError floating'}>{errorBulk.postal_code}</span>}
                                                        </div>
                                                    </div>
                                                    <Button level="primary" type="button" disabled={!isBillingAddressValid} onClick={() => assignBillingToOrder()} className={styles.full}>
                                                        <ContentfulTranslationText keyName="continue">
                                                            Continue
                                                        </ContentfulTranslationText>
                                                    </Button>
                                                </div>
                                            )}
                                        </div>

                                        <div className={clsx(styles.step)} id="section-payment" name="section-payment">
                                            <div className={styles.stepHeader}>
                                                <span className={styles.stepNumber}>{shippingRequired ? '5' : '4'}</span>
                                                <span className={styles.stepTitle}>
                                                    <ContentfulTranslationText keyName="confirm">
                                                        Confirm
                                                    </ContentfulTranslationText>
                                                </span>
                                            </div>
                                            {currentStep === 'payment' && (
                                                <div className={styles.stepBody}>
                                                    <div className="formField">
                                                        <label htmlFor="additional_notes">
                                                            <ContentfulTranslationText keyName="additionalNotes">
                                                                Additional Notes
                                                            </ContentfulTranslationText>
                                                             <i>(<ContentfulTranslationText keyName="optional">Optional</ContentfulTranslationText>)</i></label>
                                                        <textarea id="additional_notes" defaultValue={customerMessage} onChange={(e) => handleCustomerMessage(e.target.value)} />
                                                    </div>
                                                    <div className={styles.customerSummary}>
                                                        <strong><ContentfulTranslationText keyName="customer">Customer</ContentfulTranslationText>: </strong>{checkoutEmail}
                                                    </div>
                                                    <div className={`flex-between ${styles.addressSummary}`}>
                                                        {shippingRequired && (
                                                            <div className={styles.shippingSummary}>
                                                                <strong>{cartShippingMethod === 'collect' ? 'Collection' : (<ContentfulTranslationText keyName="shipping">Shipping</ContentfulTranslationText>)}:</strong><br />
                                                                {shippingAddress && shippingAddress.first_name} {shippingAddress && shippingAddress.last_name}<br/>
                                                                {(shippingAddress && shippingAddress.company && shippingAddress.company !== '') && (
                                                                    <>
                                                                        {shippingAddress.company}<br />
                                                                    </>
                                                                )}
                                                                {shippingAddress && shippingAddress.address1}<br />
                                                                {(shippingAddress && shippingAddress.address2 && shippingAddress.address2 !== '') && (
                                                                    <>
                                                                        {shippingAddress.address2}<br />
                                                                    </>
                                                                )}
                                                                {shippingAddress && shippingAddress.city}<br />
                                                                {(shippingAddress && shippingAddress.state_or_province && shippingAddress.state_or_province !== '') && (<>{shippingAddress.state_or_province}, </>)}{shippingAddress && shippingAddress.postal_code}<br />
                                                                {shippingAddress && countries && countries.find(country => country.node.country_iso2 === shippingAddress.country_code)?.node.country}<br />
                                                                {shippingAddress && shippingAddress.phone}<br />
                                                                {(shippingAddress && shippingAddress.comments && shippingAddress.comments !== '') && (
                                                                    <>
                                                                        <br />{shippingAddress.comments}
                                                                    </>
                                                                )}
                                                            </div>
                                                        )}
                                                        <div className={styles.billingSummary}>
                                                            <strong><ContentfulTranslationText keyName="billing">Billing</ContentfulTranslationText>:</strong><br />
                                                            {billingAddress && billingAddress.first_name} {billingAddress && billingAddress.last_name}<br/>
                                                            {(billingAddress && billingAddress.company && billingAddress.company !== '') && (
                                                                <>
                                                                    {billingAddress.company}<br />
                                                                </>
                                                            )}
                                                            {billingAddress && billingAddress.address1}<br />
                                                            {(billingAddress && billingAddress.address2 && billingAddress.address2 !== '') && (
                                                                <>
                                                                    {billingAddress.address2}<br />
                                                                </>
                                                            )}
                                                            {billingAddress && billingAddress.city}<br />
                                                            {(billingAddress && billingAddress.state_or_province && billingAddress.state_or_province !== '') && (<>{billingAddress.state_or_province}, </>)}{billingAddress && billingAddress.postal_code}<br />
                                                            {billingAddress && countries && countries.find(country => country.node.country_iso2 === billingAddress.country_code)?.node.country}<br />
                                                            {billingAddress && billingAddress.phone}
                                                        </div>
                                                    </div>

                                                    <div className={styles.shippingMethodSummary}>
                                                        <strong><ContentfulTranslationText keyName="shippingMethod">Shipping method</ContentfulTranslationText>: </strong>
                                                            {shippingRequired && (
                                                                <>
                                                                    {cartShippingMethod === 'collect' && (
                                                                        <>
                                                                            Store pickup - <CurrencyFormatter amount={0} currency={currency} taxCalc={false} />
                                                                        </>
                                                                    )}
                                                                    {cartShippingMethod !== 'collect' && (
                                                                        <>
                                                                            {shippingMethod.description && (translations[shippingMethod.description] ? translations[shippingMethod.description].title : shippingMethod.description)} - <CurrencyFormatter amount={shippingMethod.cost} currency={currency} taxCalc={false} />
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                            {!shippingRequired && (
                                                                <>Email</>
                                                            )}
                                                    </div>
                                                    {(checkoutFailMessage && checkoutFailMessage.length > 0) && (
                                                        <div className={styles.errorMessages}>
                                                            {checkoutFailMessage.map((msg, msgIndex) => <span key={msgIndex}>{msg}</span>)}
                                                        </div>
                                                    )}
                                                    <Button level="primary" type="button" disabled={confirmingCheckoutData} className={styles.full} onClick={validateCheckoutData}>{confirmingCheckoutData ? (`${get(translations, 'pleaseWait.title', 'Please wait')}...`) : (get(translations, 'proceedToPayment.title', 'Proceed to Payment'))}</Button>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            <CartSummary cartType="full" nextStepValid={allProductsAvailable} cartStep={cartStep} cartFunc={() => toggleNextStep('account')} />
                            {/* remove recommended add ons on mobile */}
                            {/* <div className={styles.recommendedAddOnsContainerMobile}>
                                <span className={styles.title}>Recommended add-ons</span>
                                will change this to recommended add-ons
                                <RecentlyViewed basicCardView></RecentlyViewed>
                            </div> */}
                        </div>
                    </div>
                )}
            </Container>
        </Layout>
    );
};

export default ConfirmOrderPage;
