// extracted by mini-css-extract-plugin
export var cartSection = "confirm-order-module--cartSection--XIsfn";
export var bcCartTitle = "confirm-order-module--bcCartTitle--5OKm4";
export var breadCrumbsContainer = "confirm-order-module--breadCrumbsContainer--yOJyu";
export var continueShopping = "confirm-order-module--continueShopping--MsHS2";
export var bcCartEmpty = "confirm-order-module--bcCartEmpty--4txgA";
export var bcCartContainer = "confirm-order-module--bcCartContainer--uS-Xs";
export var textBanner = "confirm-order-module--textBanner--RrhAk";
export var recommendedAddOnsContainer = "confirm-order-module--recommendedAddOnsContainer--M8hd0";
export var title = "confirm-order-module--title--ienlc";
export var recommendedAddOnsContainerMobile = "confirm-order-module--recommendedAddOnsContainerMobile--Q2E5W";
export var step = "confirm-order-module--step--E9VII";
export var stepHeader = "confirm-order-module--stepHeader--mC52o";
export var stepNumber = "confirm-order-module--stepNumber--Hk94P";
export var completed = "confirm-order-module--completed--IDnz6";
export var stepTitle = "confirm-order-module--stepTitle--Udx+O";
export var setValue = "confirm-order-module--setValue--MDOj2";
export var stepAction = "confirm-order-module--stepAction--jZaVa";
export var stepBody = "confirm-order-module--stepBody--GE7vA";
export var full = "confirm-order-module--full--PIIiH";
export var addressMessage = "confirm-order-module--addressMessage--sAYf6";
export var manualAddressTrigger = "confirm-order-module--manualAddressTrigger--dQ-KN";
export var supportFunction = "confirm-order-module--supportFunction--z8Ov6";
export var addressSearchResults = "confirm-order-module--addressSearchResults--dnx2H";
export var fieldNote = "confirm-order-module--fieldNote--sCEP5";
export var customerSummary = "confirm-order-module--customerSummary--XaCv-";
export var addressSummary = "confirm-order-module--addressSummary--OVocy";
export var shippingSummary = "confirm-order-module--shippingSummary--aYCv3";
export var billingSummary = "confirm-order-module--billingSummary--P7D+8";
export var shippingMethodSummary = "confirm-order-module--shippingMethodSummary--ZIR02";
export var shippingMethods = "confirm-order-module--shippingMethods--KVcgO";
export var displayOptions = "confirm-order-module--displayOptions--MqJ3q";
export var byline = "confirm-order-module--byline--26UuL";
export var loadingShipping = "confirm-order-module--loadingShipping--0PkWO";
export var spinnerWrapper = "confirm-order-module--spinnerWrapper--xvVxL";
export var errorMessages = "confirm-order-module--errorMessages--bZSJ1";
export var formFields = "confirm-order-module--formFields--E3W34";
export var guestEmailWrapper = "confirm-order-module--guestEmailWrapper--t9MT7";
export var guestEmailOptions = "confirm-order-module--guestEmailOptions--CTm32";
export var checkoutEmail = "confirm-order-module--checkoutEmail--VOi6x";