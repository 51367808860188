import React from 'react'
import * as styles from './Radio.module.css'

const Radio = ({ value, label, id, name, action, isChecked, divClick = true }) => {

  const clickHandler = (e) => {
    action(value);
    e.stopPropagation();
  }

  return (
      <div role={'presentation'} className={`${styles.radioWrapper} ${divClick === true ? styles.pointer : ''}`} 
      onClick={divClick === true ? clickHandler : undefined}>
          <div className={styles.inputWrapper}>
              <input type="radio"
               id={id} 
               className={styles.input}
               value={value}
               name={name} 
               data-label={label} 
               onChange={clickHandler} 
               checked={isChecked} />
              <span className={styles.circle} role="presentation">
                <svg>
                  <circle cx="5" cy="5" r="5" fill="currentColor" />
                </svg>
              </span>
          </div>
          <label className={`${styles.label} ${divClick === true ? styles.pointer : ''}`} forhtml={id}>{label}</label>
      </div>
  )
};

export default Radio;