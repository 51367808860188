/**
 * HERE Autocomplete helper
 * 
 */

 async function hereApi(endpoint, method, body) {
  const options = {
      method: method ? method : 'GET',
      credentials: 'same-origin',
      mode: 'same-origin'
  };
  
  if (body) {
      let bodyString = body;
      if (typeof body === 'object') {
      bodyString = JSON.stringify(body);
      }
  
      options.body = bodyString;
  }
  
  const parseJson = async response => {
      const text = await response.text();
      try {
      const json = JSON.parse(text);
      return json;
      } catch (err) {
      return text;
      }
  };
  
  return await fetch(
      `${process.env.GATSBY_LAMBDA_PATH}here?endpoint=${btoa(endpoint)}`,
      options
  ).then(async res => ({ response: await parseJson(res), status: res.status }));
}

 async function autoComplete(address) {
  if (address.length >= 3) {
    //   const suggestions = await hereApi(`autocomplete?q=${encodeURI(address)}`);
        const suggestions = await hereApi(`suggest.json?query=${encodeURI(address)}`);
        // console.log("Predict", suggestions);
        if (suggestions.status === 200) {
            if ('suggestions' in suggestions.response) {
                return {type: 'results', results: suggestions.response.suggestions};
            }else if ('items' in suggestions.response) {
                return {type: 'results', results: suggestions.response.items};
            } else if ('message' in suggestions.response) {
                return {type: 'message', message: suggestions.response.message};
            } else {
                return {type: 'error', error: suggestions.response};
            }
        } else {
            return {type: 'error', error: (typeof suggestions.response === 'object' && 'messages' in suggestions.response && suggestions.response.messages[0]) || suggestions.response};
        }
  } else {
      return {type: 'invalid', invalid: 'Requires more characters for search'};
  }
}

export { 
  hereApi,
  autoComplete
};